<template>
  <section class="finder">
    <div class="finder__container" ref="container">
      <input
        class="finder__input"
        type="text"
        placeholder="Buscar..."
        v-model="search"
        @keyup="onKeyUp"
        @focus="onFocus"
        @blur="onBlur"
      />
      <i class="finder__ico fas fa-search"></i>
    </div>
  </section>
</template>

<script>
//Controla cuando termina de escribir
let timeout;

export default {
  data: function() {
    return {
      search: ""
    };
  },
  methods: {
    onKeyUp: function() {
      //Retrasa hasta que dejan de escribir
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        this.$emit("onSearch", this.search);
      }, 500);
    },
    onFocus: function(e) {
      this.$refs.container.style.border = "2px solid #515151";
    },
    onBlur: function(e) {
      this.$refs.container.style.border = "2px solid #adaeae";
    }
  }
};
</script>

<style lang="scss">
.finder {
  @include Row(flex-end);
  width: 100%;
  &__container {
    margin-right: 20px;
    @include Row();
    width: 100%;
    height: 38px;
    padding: 5px;
    position: relative;
    border: 2px solid $gray-edward;
    border-radius: 4px;
    box-shadow: 1px 1px 5px $gray-edward;
    transition: border 500ms;
  }
  &__input {
    width: 100%;
    height: 100%;
    margin-left: 20px;
    color: black;
    background-color: transparent;
    border: none;
    outline: none;
    & ~ i {
      position: absolute;
      left: 0;
      left: 5px;
      top: 50%;
      color: $gray-edward;
      transition: color 500ms;
      transform: translateY(-50%);
    }
    &:focus ~ i {
      color: $gray-emperor;
    }
    &:focus {
      .finder__container {
        border: 2px solid $gray-emperor;
      }
    }
  }
  @media (min-width: $tablet_width) {
    &__container {
      width: 300px;
    }
    &__input {
      display: block;
    }
  }
}
</style>
